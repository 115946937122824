import React from "react"
import styled from "styled-components"
import Link from "next/link"

const StyledContainer = styled.div`
  padding: 4px 8px;
  border: 2px solid ${(props) => props.theme.palette.colors.typography[1000]};
  color: ${(props) => props.theme.palette.colors.typography[1000]};
  white-space: nowrap;
  transition: background-color 300ms ease;
  border-radius: 100px;
  text-overflow: ellipsis;
  overflow: hidden;
  font-size: 13px;

  &:hover {
    border: 2px solid ${(props) => props.theme.palette.colors.purple[700]};
    color: ${(props) => props.theme.palette.colors.purple[700]};
  }
`

export const Tag = ({ className, text, path }) => {
  return (
    <Link
      href={path}
      style={{ textDecoration: "none", color: "unset" }}
      className={className}
      title={text}
    >
      <StyledContainer>{text}</StyledContainer>
    </Link>
  )
}
